/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { getUserConfiguration } from '@/auth/utils';
import outputInvoiceStoreModule from '../inputInvoiceStoreModule';

export default function useOutputInvoicePreviewModal(props) {
  const STORE_MODULE_NAME = 'outputInvoice';
  const refModal = ref(null); // Tham chiếu modal nội bộ
  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, outputInvoiceStoreModule);
  }
  const previewUrl = ref(null);
  const draftInvoice = ref(null)
  const viewDraftInvoice = ref(false)
  const toastification = toast();

  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const resetModal = () => {
    resetItemLocal();
    draftInvoice.value = null
    viewDraftInvoice.value = false
  };


  const pdfUrl = ref(null);
  const isLoading = ref(false);
  const isHidden = ref(false);

  const showModal = () => {
    resetModal();
    getViettelInvoiceDraft();
    getIsHidden();
  };

  const getIsHidden = () => {
    const data = JSON.parse(itemLocal.value.viettelInvoiceResult);
    if (data.paymentStatus === 1) {
      isHidden.value = true;
    }
    else {
      isHidden.value = false;
    }
  };

  const getViettelInvoiceDraft = () => {
    if (viewDraftInvoice.value === false && !draftInvoice.value) {
      isLoading.value = true
      store
        .dispatch('outputInvoice/previewReleaseInvoice', itemLocal.value.id)
        .then(response => {
          draftInvoice.value = response.data
          viewDraftInvoice.value = true
          isLoading.value = false
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = false
        });
    } else {
      viewDraftInvoice.value = false
    }
  };

  const updatePaymentStatus = () => {
    store
      .dispatch('outputInvoice/updatePaymentStatusReleaseInvoice', itemLocal.value.id)
      .then(() => {
        toastification.showToastSuccess("Đã chuyển trạng thái sang đã thanh toán");
        if (props.fetchData) {
          props.fetchData();
        }
        if (refModal.value) {
          refModal.value.hide(); // Đóng modal
        }
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const cancelPaymentStatus = () => {
    store
      .dispatch('outputInvoice/cancelPaymentStatusReleaseInvoice', itemLocal.value.id)
      .then(() => {
        toastification.showToastSuccess("Đã chuyển trạng thái sang chưa thanh toán");
        if (props.fetchData) {
          props.fetchData();
        }
        if (refModal.value) {
          refModal.value.hide(); // Đóng modal
        }
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  return {
    refModal,
    itemLocal,
    resetItemLocal,
    resetModal,
    showModal,
    getViettelInvoiceDraft,
    pdfUrl,
    isLoading,
    isHidden,
    previewUrl,
    draftInvoice,
    viewDraftInvoice,
    cancelPaymentStatus,
    updatePaymentStatus,
  };
}
